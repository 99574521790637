<template>
  <!-- 北美SDE求职旗舰班试听 -->
  <div class="vip-page">
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
			<div class="banner-grid">
				<h3>北美CS留学申请</h3>
				<p>专注北美计算机留学申请 <br>十年北美计算机领域留学求职经验，留学求职一体化</p>
			</div>
        </div>
        <div
          v-if="
            TabActiveIndex === 'course_intro' ||
            TabActiveIndex === 'course_price'
          "
        >
		<div class="g-section">
			<div class="g-header">
				<h3>留学阶段规划求职，北美CS留学求职不分家</h3>
				<p>助力留美成功，喜提梦校offer</p>
			</div>
			<div class="container">
				<div class="need-cells">
					<div class="col">
						<div class="need-card hoverUp">
							<div class="icon"><img src="../../assets/images/13-CsStudy/mao.png" alt="" /></div>
						<h3>北美CS硕士</h3>
						<p>毕业留美工作，拿绿卡<br> 提升学历，回国高薪工作</p>
						</div>
					</div>
					<div class="col">
						<div class="need-card hoverUp">
							<div class="icon"><img src="../../assets/images/13-CsStudy/scholl.png" alt="" /></div>
						<h3>在职“润”美</h3>
						<p>想要逃离国内996、35危机 <br>“润”美，不知如何选校规划</p>
						</div>
					</div>
					<div class="col">
						<div class="need-card hoverUp">
							<div class="icon"><img src="../../assets/images/13-CsStudy/project.png" alt="" /></div>
						<h3>转码选手</h3>
						<p>学业工作背景不相关 <br>不知如何补充背景，选校规划</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="g-section offer-section">
			<div class="g-header">
				<h3>留学阶段就为美国求职铺垫，名校名企offer双丰收</h3>
				<p>定制专属版北美CS留学解决方案，贯穿留学、求职、留美</p>
			</div>
			<div class="container">
				<div class="offer-list u-flex">
					<div class="col">
						<div class="offer-panel">
							<div class="offer-card">
								<div class="offer-tt">提升背景</div>
								<div class="offer-body">
									<p>从北美CS求职视角 </p>
									<p>实习/项目计划，补充相关背景；从申请视角确定GPA、论文、留学标准化考试安排。</p>
								</div>
							</div>
							<div class="offer-down">
								<img src="../../assets/images/13-CsStudy/down.png" alt="" />
							</div>
							<div class="offer-button">01</div>
						</div>
					</div>
					<div class="col">
						<div class="offer-panel">
							<div class="offer-card">
								<div class="offer-tt">职业发展</div>
								<div class="offer-body">
									<p>1v1挖掘申请者亮点</p>
									<p>从当前北美求职市场情 况提供指导以及其他建 议。</p>
								</div>
							</div>
							<div class="offer-down">
								<img src="../../assets/images/13-CsStudy/down.png" alt="" />
							</div>
							<div class="offer-button">02</div>
						</div>
					</div>
					<div class="col">
						<div class="offer-panel">
							<div class="offer-card">
								<div class="offer-tt">文书撰写</div>
								<div class="offer-body text-center">
									<p>1篇PS</p>
									<p>1篇CV</p>
									<p>3封推荐信</p>
								</div>
							</div>
							<div class="offer-down">
								<img src="../../assets/images/13-CsStudy/down.png" alt="" />
							</div>
							<div class="offer-button">03</div>
						</div>
					</div>
					<div class="col">
						<div class="offer-panel">
							<div class="offer-card">
								<div class="offer-tt">选校申请</div>
								<div class="offer-body">
									<p>从申请者实际需求出发</p>
									<p>结合学校项目特点，1v1 选校指导，匹配18所院校，全程办理网申服务，同步进度。</p>
								</div>
							</div>
							<div class="offer-down">
								<img src="../../assets/images/13-CsStudy/down.png" alt="" />
							</div>
							<div class="offer-button">04</div>
						</div>
					</div>
					<div class="col">
						<div class="offer-panel">
							<div class="offer-card">
								<div class="offer-tt">行前指导</div>
								<div class="offer-body">
                  <p>留学安全事项告知</p>
									<p>为申请者安排出国培训，告知学生安全事项及生活常识，帮助了解留学国家及学校生活。</p>
								</div>
							</div>
							<div class="offer-down">
								<img src="../../assets/images/13-CsStudy/down.png" alt="" />
							</div>
							<div class="offer-button">05</div>
						</div>
					</div>
					<div class="col">
						<div class="offer-panel">
							<div class="offer-card">
								<div class="offer-tt">求职规划</div>
								<div class="offer-body">
                  <p>学校后紧跟求职offer</p>
									<p>拿到offer之后，根据北美就业市场情况以及学生背景，定制入学后专属求职时间安排，助力拿到大厂offer。</p>
								</div>
							</div>
							<div class="offer-down">
								<img src="../../assets/images/13-CsStudy/down.png" alt="" />
							</div>
							<div class="offer-button">06</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="g-section" style="background-color: #F7F8F8;">
			<div class="g-header">
				<h3>覆盖两大人群，高排名+留美双需求满足，进退灵活</h3>
				<p>应届留学、转码、跳槽，国内国外求职皆可</p>
			</div>
			<div class="container">
				<div class="cover-cells">
					<div class="col">
						<div class="cover-card">
							<div class="cover-tag">A</div>
							<img src="../../assets/images/13-CsStudy/plane.png" alt="" />
							<h3>以提升学历为主，高排名名校为优先</h3>
							<p>兼顾好学校和北美+国内求职双需求</p>
						</div>
					</div>
					<div class="col">
						<div class="cover-card">
							<div class="cover-tag">B</div>
							<img src="../../assets/images/13-CsStudy/data.png" alt="" />
							<h3>以留美为主，在职/转码/正常留学人群</h3>
							<p>求职视角地理位置选校</p>
						</div>
					</div>
				</div>
				<div class="cover-data">
					<div class="circle-box">
						留美为目标
					</div>
					<div class="circle-box">
						转码选手
					</div>
					<div class="circle-box">
						在职留学
					</div>
					<div class="circle-box">
						应届留学<br>排名为主
					</div>
					<div class="circle-box">
						国内外求职
					</div>
				</div>
			</div>
		</div>
		<div class="g-section core-section ">
			<div class="g-header">
				<h3>以学生为核心，独特的留学申请立体服务系统</h3>
				<p>背景评估系统、职业发展及专业匹配系统、背景拓展系统和文书操作系统，以北美顾问网络提供支持</p>
			</div>
			<div class="container">
				<div class="core-cells">
					<div class="core-card hoverUp">
						<div class="tt">专业背景分析</div>
						<div class="bd">
							全面分析和挖掘学生的优势和劣势，确定申请策略
						</div>
					</div>
					<div class="core-card hoverUp">
						<div class="tt">背景提升总体规划</div>
						<div class="bd">
							按学生背景，定制背景提升规划，给予建议和方向，提升竞争力
						</div>
					</div>
					<div class="core-card hoverUp">
						<div class="tt">出国考试时间规划</div>
						<div class="bd">
							全面分析和挖掘学生的优势和劣势，确定申请策略
						</div>
					</div>
					<div class="core-card hoverUp">
						<div class="tt">留学文书创意及<br>写作指导</div>
						<div class="bd">
							专业文书老师1对1指导，确保学生文书的个性化和专业化
						</div>
					</div>
					<div class="core-card hoverUp">
						<div class="tt">选校申请规划</div>
						<div class="bd">
							完善的申请流程，专业的流程申请及复核审查，杜绝申请中的失误和任何瑕疵
						</div>
					</div>
					<div class="core-card hoverUp">
						<div class="tt">成功拿到offer</div>
						<div class="bd">
							顺利拿到梦想学校的offer
						</div>
					</div>
					<div class="core-card hoverUp">
						<div class="tt">出国考试时间规划</div>
						<div class="bd">
							为未来的北美CS求职进行规划，8月入学，美国没入学已经开始招聘
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<div class="g-section adv-section" >
			<div class="g-header">
				<h3>对比其他留学机构，我们的优势更明显</h3>
				<p>从求职就业视角看待留学，定制化解决方案，名校和名企offer双丰收</p>
			</div>
			<div class="container">
				<div class="adv-cells">
					<div class="col">
						<div class="adv-card hoverUp">
							<div class="adv-tt">CSON</div>
							<ul class="adv-list">
								<li>
									<div class="adv-box">
										<h3>专注领域 </h3>
										<p>专注北美计算机留学求职十年</p>
									</div>
								</li><li>
									<div class="adv-box">
										<h3>申请视角  </h3>
										<p>从求职就业视角申请，贯穿留学、求职、留美</p>
									</div>
								</li><li>
									<div class="adv-box">
										<h3>offer </h3>
										<p>名校offer+名企offer</p>
									</div>
								</li><li>
									<div class="adv-box">
										<h3>留学方案 </h3>
										<p>本硕博、转码、“润”美均有定制化解决方案</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="col">
						<div class="adv-card adv-gray-card hoverUp">
							<div class="adv-tt">其他留学机构</div>
							<ul class="adv-list">
								<li>
									<div class="adv-box">
										<h3>专注领域 </h3>
										<p>各国家各领域申请，不够精专</p>
									</div>
								</li><li>
									<div class="adv-box">
										<h3>申请视角  </h3>
										<p>有学校给offer就行</p>
									</div>
								</li><li>
									<div class="adv-box">
										<h3>offer </h3>
										<p>保底学校offer</p>
									</div>
								</li><li>
									<div class="adv-box">
										<h3>留学方案 </h3>
										<p >流水线申请，无定制方案</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="g-section" style="background: #F7F8F8;">
			<div class="g-header">
				<h3>CSON成功上岸案例（部分）</h3>
				<p>在专业资深导师帮助下，同学们收获梦校offer</p>
			</div>
			<div class="container">
				<ul class="student-list">
					<li>
						<div class="student-card hoverUp" >
							<div class="stu-grid">
								<h3>许同学</h3>
								<p>录取结果：约翰·霍普金斯大学-信息系统专业，圣路易斯华盛顿大学-计算机专业</p>
								<p>背景：国内一本，化学专业</p>
								<p>基本成绩：GPA3.52， GRE321， </p>
								<p>托福103</p>
								<p>实习科研：无</p>
							</div>
						</div>
					</li>
					<li>
						<div class="student-card hoverUp">
							<div class="stu-grid">
								<h3>吴同学</h3>
								<p>录取结果：布兰迪斯大学-计算机硕士，密歇根安娜堡分校-数据科学硕士</p>
								<p>背景：国内211， 美国TOP50， 会计专业</p>
								<p>基本成绩：GPA3.8， GRE325， </p>
								<p>托福108</p>
								<p>工作经历：国内知名会计事务所工作4年</p>
							</div>
						</div>
					</li><li>
						<div class="student-card hoverUp">
							<div class="stu-grid">
								<h3>李同学</h3>
								<p>录取结果：伊利诺伊大学香槟分校-计算机硕士，南加州大学-计算机硕士</p>
								<p>背景：国内985，计算机专业GPA3.61， </p>
								<p>GRE322， 托福101</p>
								<p>工作经历：国内大厂BAT工作5年</p>
							</div>
						</div>
					</li>
					<li>
						<div class="student-card hoverUp">
							<div class="stu-grid">
								<h3>刘同学</h3>
								<p>录取结果：德克萨斯大学达拉斯分校-计算机硕士，佛罗里达大学-计算机硕士，Amazon实习offer</p>
								<p>背景：国内三本，新闻专业</p>
								<p>基本成绩：GPA3.32， GRE314， 托福94</p>
								<p>工作经历：</p>
								<p>一段国内报社实习，一个国内小厂程序员实习</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		
		
		
          <div class="g-section leet-jj-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>课程咨询</h3>
            </div>
            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div
                      class="gg-card"
                      style="margin: 0 auto !important"
                  >
                    <el-row>
                      <el-col >
                        <div class="gg-yh-cell">
                          <div class="gg-yh-grid">
                           <div class="gg-weixin">
                           		 <img src="../../assets/images/lxZixun/客服微信.jpeg" alt="" />
                           </div> 
                            
                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
<!--                              立即报名，立即开始，前置课程提前学习<br />不浪费一分一秒，报名立刻学习-->
							  专属定制化北美CS留学解决方案<br />
							  贯穿留学、求职、留美，名校&名企offer双丰收
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1确定实习/项目计划
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1指导提升学术背景
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >提升学生输出核心能力
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1制定合理的考试计划
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1文书撰写
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1选校指导，18所匹配院校
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >全程办理网申服务，同步进度等
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1行前指导&求职规划
                              </li>
                            </ul>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  mixins: [],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      videoShow: false,
      bantxt: "北美CS求职课",
      dr: 10,
      br: 10,
      txShow: true,
      curId: 0,
      tradio: 3,
      bzid: 0,
      Pselect: "1",
      img: require("../../assets/images/13-CsStudy/banner2.jpg"),
      form: {
        region: "v1",
      },
      activeNames: ["1"],
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        // { name: "班课套餐", index: "1", tag: "course_meal" },
        { name: "班课大纲", index: "1", tag: "course_content" },
        // { name: "班课模式", index: "2", tag: "course_mode" },
        // {name: "用户评价", index: "1", tag: "course_comment"},
        // { name: "售前咨询", index: "2", tag: "course_question" },
      ],
      currentIndex: "0",
      recommendClasses: [], //相关课程推荐byzuhuimin
      helpVisible: false,
      countDownText: "",
      countDownTimeStamp: -1,
      countDays: 0,
      countHours: 0,
      countMinutes: 0,
      countSeconds: 0,
      qrcode: {},
    };
  },
  created() {
    // this.setEndTime(10);
    console.log(this.startTime);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    echange(val) {
      this.bzid = val;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
    timeCountDown(endDateStr) {
      if (endDateStr == -1) {
        return;
      }
      //结束时间
      var endDate = new Date(endDateStr);
      //当前时间
      var nowDate = new Date();
      //相差的总秒数
      if (endDate < nowDate) {
        window.location.reload();
      }
      var totalSeconds = parseInt((endDate - nowDate) / 1000);
      //天数
      var days = Math.floor(totalSeconds / (60 * 60 * 24));

      //取模（余数）
      var modulo = totalSeconds % (60 * 60 * 24);

      //小时数
      var hours = Math.floor(modulo / (60 * 60));

      modulo = modulo % (60 * 60);
      //分钟
      var minutes = Math.floor(modulo / 60);
      //秒

      var seconds = modulo % 60;

      this.countDays = days;
      this.countHours = hours;
      this.countMinutes = minutes;
      this.countSeconds = seconds >= 10 ? seconds : `0${seconds}`;

      setTimeout(() => {
        this.timeCountDown(endDateStr);
      }, 1000);
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/13-CsStudy.css");
.gg-weixin{
	img{
		width: 124px;
		height: 124px;
	}
}
.leet-jj-section .gg-yh-grid::after{
	display: none;
}
.page-banner{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.banner-grid h3{
	font-size: 56px;
	margin-bottom: 36px;
}
.banner-grid p{
	font-size: 16px;
	line-height: 1.8;
}

</style>
